import { Redirect } from "react-router"
import styled from "styled-components"

import { Divider } from "@material-ui/core"

import { ImageBackdrop } from "src/components/ImageBackdrop/ImageBackdrop"
import { OnboardingCurrentUser } from "src/components/Onboarding/OnboardingCurrentUser"
import { breakpoint } from "src/constants/breakpoints"
import { HREF_MINUT_ORGANIZATIONS_MEMBER_INVITE } from "src/constants/hrefs"
import { useAppLocation } from "src/hooks/useAppLocation"
import { useTranslate } from "src/i18n/useTranslate"
import { getInviteCodeFromUrl } from "src/router/organizationRoutes"
import { Routes } from "src/router/routes"
import { MLocation } from "src/router/routeTypes"
import { MButtonLegacy } from "src/ui/Button/MButtonLegacy"
import { backgroundGrayV3 } from "src/ui/colors"
import backdropImage from "src/ui/images/photo-stockholm-skyline-portrait.jpg"
import backdropImagePortrait from "src/ui/images/photo-stockholm-skyline-portrait.jpg"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function Onboarding() {
  const inviteCode = getInviteCodeFromUrl()
  const location = useAppLocation()

  const joinLink: MLocation = {
    ...location,
    ...Routes.OnboardingJoinOrganization.location(),
  }

  const createLink = {
    ...location,
    ...Routes.OnboardingCreateOrganization.location(),
  }

  if (inviteCode) {
    return <Redirect to={joinLink} />
  }

  return <OnboardingNewAccount createLink={createLink} joinLink={joinLink} />
}

function OnboardingNewAccount({
  joinLink,
  createLink,
}: {
  joinLink: MLocation
  createLink: MLocation
}) {
  const { t, langKeys } = useTranslate()

  return (
    <ImageBackdrop
      landscapeImageUrl={backdropImage}
      portraitImageUrl={backdropImagePortrait}
      backgroundColor={backgroundGrayV3}
    >
      <StyledCard>
        <CardContents>
          <MText variant="heading1">{t(langKeys.lets_get_started)}</MText>

          <MButtonLegacy appHref={createLink} fullWidth size="large">
            {t(langKeys.organization_create)}
          </MButtonLegacy>

          <MButtonLegacy
            variant="outlined"
            appHref={joinLink}
            fullWidth
            size="large"
          >
            {t(langKeys.join_organization)}
          </MButtonLegacy>

          <Footer>
            <Divider />

            <MText variant="bodyS" color="secondary">
              {t(langKeys.organizations_create_organization_to_manage_text)}

              <div>
                {t(langKeys.organizations_organization_exists_question)}{" "}
                <ExternalLink href={HREF_MINUT_ORGANIZATIONS_MEMBER_INVITE}>
                  {t(
                    langKeys.organizations_organization_invite_required_link_text
                  )}
                </ExternalLink>
              </div>
            </MText>

            <OnboardingCurrentUser />
          </Footer>
        </CardContents>
      </StyledCard>
    </ImageBackdrop>
  )
}

const Footer = styled.div`
  display: grid;
  gap: ${spacing.L};
`

const CardContents = styled.div`
  display: grid;
  gap: ${spacing.L};
  max-width: 55ch;
  margin: ${spacing.XS};

  @media (${breakpoint.mediumUp}) {
    margin: ${spacing.L};
  }
`

const StyledCard = styled(MCard)`
  max-width: 60ch;
`
